import { useEffect, useState } from "react";
import phoneIcon2 from "../image/phone-forwarded (1).png"
import phoneIcon from "../image/phone-forwarded.png"


export default function Massaging(props) {
    const [data, setData] = useState([])
    const width = window.innerWidth

    useEffect(() => {
        if (props.listCus) {
            setData(props.listCus)
            return;
        }
    }, [props.listCus])

    return (
        <>
            {data.length > 0 ? (
                <div className="block-cover">
                    {data.map((item) => (
                        <div className="block-type-2">
                            <div className="block-header">
                                <div className="cus-name">{item.customerName}</div>
                                <div className="cus-phone">
                                    <div className="cus-phone__icon">
                                        <img className="img-col" src={phoneIcon2} alt="" />
                                        <img className="img-row" src={phoneIcon} alt="" />
                                    </div>
                                    <div className="cus-phone__number">{item.customerPhone}</div>
                                </div>
                            </div>
                            <div className="block-body">
                                <div className="left">
                                    <span className="text">
                                        {item?.waitServe < 30
                                            ? `Còn ${item?.waitServe} phút`
                                            : 'Đang xếp Skinner'}
                                    </span>
                                </div>
                                <div className={item.isBookOnline ? "right" : "right-2"} >

                                    <div className="cus-time" >
                                        <span className="text">{item.bookHour}</span>
                                    </div>
                                    <div className="cus-type">
                                        <span className="text">
                                            {item.isBookOnline ? "Đặt trước online" : "Đặt tại Salon"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="block-footer">
                                <div className="early" >
                                    {item?.checkinWait != 0 ?
                                        <div>
                                            {`Đến sớm ${Math.abs(Math.round(item?.checkinWait))} phút`}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (null)}
        </>
    );
}

